import React from 'react';
import Helmet from 'react-helmet';
import Layout from '@components/Layout';
import Content from '@components/Content';
import ContactIcon from '@assets/contact-sent-inline.svg'


const Success = props => (
  <Layout>
    <Helmet>
      <title>Propertyc Center - Wiadomoć została wysłana poprawnie!</title>
    </Helmet>
<Content>
            <h1 className="heading">Wiadomość została wysłana poprawnie!</h1>
            <div className="contact">
                <div className="contact__image">
                    <ContactIcon className="contact__icon" />
                </div>
                <div className="contact__form">
                    <p>Dziękujemy za wysłanie wiadomości! Wkrótce skontaktujemy się z Tobą.</p>
                </div>
            </div>
        </Content>
  </Layout>
);

export default Success;
